import React, {useState, useEffect} from "react";
import { TextField, Typography, Box, Paper, Link, Stack, Alert, Collapse, IconButton, AlertTitle, Divider, Card, CardContent, ThemeProvider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import "../../css/Consent.css";
import { HomePageTheme } from "../../theme/theme";
import C1 from "../../assets/images/Consent1.png"
import C2 from "../../assets/images/Consent2.png"


export default function Consent () {


    return (
        <section className="background">
            <ThemeProvider theme={HomePageTheme}>
            <Box
            sx={{
                pt: 1,
                my: 8,
                mx: 2,
                pb: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >
                <Card elevation={0}>
                    <CardContent>
                <Stack spacing={1.1}>
                <Typography textAlign={'left'} variant="h4" fontWeight={'bold'}>
                    Consent form
                </Typography>

                <Typography textAlign={'left'} variant="subtitle1">
                    This form is used to give user consent to message rates. 
                </Typography>            
                <Stack direction={'row'}>
                <img className="img" src={C1} height={390} width={600} style={{ display: 'block', margin: 'auto'}}/>
                <img className="img" src={C2} height={390} width={600} style={{ display: 'block', margin: 'auto'}}/>
                </Stack>
            
                </Stack>
                </CardContent>
                </Card>

            </Box>
            </ThemeProvider>
        </section>
    )
}